<template>
  <div class="wrapper">
    <va-navibar></va-navibar>
    <va-slider :slideMenuItems="slideMenuItems" :agents="agents" title="代理管理"></va-slider>
    <va-content-wrap></va-content-wrap>
    <!--<footer class="main-footer">
        <div class="float-right d-none d-sm-block">
            <b>Version</b> 6.0
        </div>
        <strong>Copyright &copy; 2015-2019 <a href="http://panorover.com">PanoRover.com</a>.</strong> All rights reserved.
    </footer>-->
    <aside class="control-sidebar control-sidebar-dark">
      <div class="control-sidebar-content p-3">
        <nav v-if="setting" class="nav flex-column">
          <a class="nav-link" :href="setting.myUrl">会员中心</a>
          <a v-if="$inRole('管理员')" class="nav-link" :href="setting.adminUrl">管理中心</a>
          <a v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-link" :href="setting.myUrl+'/Public'">公共管理</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-link" :href="setting.innAdminUrl+'/Inn'">客栈管理</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-link" :href="setting.innAdminUrl+'/Agent'">代理管理</a>
        </nav>
      </div>
    </aside>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import axios from 'axios'
  import VANaviBar from '@/components/NaviBar.vue'
  import VASlider from '@/components/Slider.vue'
  import VAContentWrap from '@/components/ContentWrap.vue'
  import store from '@/vuex/store.js'

  export default {
    components: {
      'va-navibar': VANaviBar,
      'va-slider': VASlider,
      'va-content-wrap': VAContentWrap,
    },
    data() {
      return {
        signedIn: false,
        slideMenuItems: null,
        agents: [],
        agent: null,
      }
    },
    methods: {
      ...mapActions([
        //'getNoticesCount',
        //'getNotices',
        //'getMessagesCount',
        //'getMessages',
        'getSetting',
        //'getUser'
      ]),
      loadData: function () {
        this.loadMenu()

      },
      loadMenu: function () {
        var _this = this;
        this.$axios.get('/Api/InnAdmin/Menus/InnAgent').then(function (res) {
          _this.slideMenuItems = res.data;
        }).catch(err => {
          console.log(err)
          if (err) {
            _this.$message({
              message: '发生了错误 关闭此消息尝试重新加载',
              type: 'error',
              zIndex: 9999,
              showClose: true,
              duration: -1,
              onClose: () => {
                _this.loadData();
              }
            })
          }
        })
      },
      rotuerTo(data) {
        this.$router.push({ name: 'AgentHome', params: { agentId: data.id } })
      },
    },
    created: function () {
      if (this.$inRole('管理员') || this.$inRole('客栈代理') || this.$inRole('客栈代理员工') || this.$inRole('客栈店主') || this.$inRole('客栈店员')) {
        this.loadData();
      } else {
        this.$router.push({ name: 'NoAccess' })
      }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    computed: {
      ...mapGetters([
        'setting',
        //'unreadMessagesCount',
        //'unreadNotificationsCount',
        //'remainTasksCount',
        //'currentUser'
      ]),
    },
    beforeRouteEnter(to, from, next) {
      checkAgentId(to.params.agentId, next)
    },
    beforeRouteUpdate(to, from, next) {
      checkAgentId(to.params.agentId, next)
    },
    store
  }
  function checkAgentId(agentId, next) {
    var getagent
    if (agentId) {
      console.log('rr', typeof agentId)
      getagent = axios.get(`/Api/InnAdmin/Agents/${agentId}`)
    } else {
      next({ name: 'NoAccess' })
      return
    }
    axios.get("/Api/InnAdmin/Agents", {}).then(function (res) {
      var agents = res.data.data;
      getagent.then(function (res) {
        console.log(res)
        window.localStorage.agentId = agentId;
        next(vm => {
          vm.agents = agents;
          vm.agent = res.data
        })
      }).catch(function (err) {
        console.log(err)
        next({ name: 'NoAccess' })
      })
    })

  }
</script>

<style>
</style>
