<template>
  <!--<li v-if="router && router.name" class="nav-item has-treeview">
    <router-link :to="router" class="nav-link">
      <i :class="icon||'fas fa-dot-circle'" class="nav-icon"></i> <p>{{ name }}</p>-->
  <!--<span class="pull-right-container" v-show="badge">
    <small class="label pull-right" :class="[badge.type==='String'?'bg-green':'label-primary']">{{ badge.data }}</small>
  </span>-->
  <!--</router-link>
  </li>-->
  <router-link :to="router" v-if="router && router.name"
               v-slot="{ href, route, navigate, isActive, isExactActive }">
    <li class="nav-item has-treeview">
      <a class="nav-link" :class="[router.name!='Home'&&isActive&&'active',router.name=='Home'&&isExactActive&&'active']" :href="href" @click="navigate">
        <i :class="icon||'fas fa-dot-circle'" class="nav-icon"></i> <p>{{ name }}</p>
      </a>
    </li>
  </router-link>
  <li :class="getType" v-else-if="link" class="nav-item has-treeview">
    <a :href="link" :target="target" class="nav-link">
      <i :class="icon||'fas fa-dot-circle'" class="nav-icon"></i> <p>{{ name }}</p>
      <!--<span class="pull-right-container" v-show="badge">
        <small class="label pull-right" :class="[badge.type==='String'?'bg-green':'label-primary']">{{ badge.data }}</small>
      </span>-->
    </a>
  </li>
  <li :class="getType" v-else class="nav-item has-treeview">
    {{ isHeader ? name : '' }}
    <a href="javascript:" v-if="!isHeader" class="nav-link">
      <i :class="icon||'fas fa-circle'" class="nav-icon"></i>
      <p>
        {{ name }}
        <i class="right fas fa-angle-left" v-if="items.length > 0"></i>
      </p>
      <!--<span class="pull-right-container">
        <small v-if="badge && badge.data" class="label pull-right" :class="[badge.type==='String'?'bg-green':'label-primary']">{{ badge.data }}</small>
        <i v-if="items.length > 0" class="fa fa-angle-left pull-right"></i>
      </span>-->
    </a>
    <ul class="nav nav-treeview" v-if="items.length > 0">
      <va-slide-item v-for="(item,index) in items"
                     :data="item"
                     :key="index"
                     :type="item.type"
                     :isHeader="item.isHeader"
                     :icon="item.icon"
                     :name="item.name"
                     :badge="item.badge"
                     :items="item.items"
                     :router="item.router"
                     :target="item.target"
                     :link="item.link">

      </va-slide-item>
      <!--<router-link tag="li" v-for="(item,index) in items" :data="item" :key="index" :to="item.router" v-if="item.router && item.router.name">
        <a>
          <i :class="item.icon"></i> {{ item.name }}
        </a>
      </router-link>
      <li v-else>
        <a>
          <i :class="item.icon"></i> {{ item.name }}
        </a>
      </li>-->
    </ul>
  </li>
</template>

<script>
  export default {
    name: 'va-slide-item',
    props: {
      type: {
        type: String,
        default: 'item'
      },
      isHeader: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: ''
      },
      name: {
        type: String
      },
      badge: {
        type: Object,
        default() {
          return {}
        }
      },
      items: {
        type: Array,
        default() {
          return []
        }
      },
      router: {
        type: Object,
        default() {
          return {
            name: ''
          }
        }
      },
      link: {
        type: String,
        default: ''
      },
      target: {
        type: String,
        default: ''
      }
    },
    created() {
      //$('.sidebar-menu').tree()
    },
    computed: {
      getType() {
        if (this.isHeader) {
          return 'header'
        }
        return this.type === 'item' ? '' : 'treeview'
      }
    },
    watch: {

    }
  }
</script>
